.product-tabs {
  margin: 15px 0 40px;
  
  &__title {
    font-size: 25px;
    line-height: 1.1;
    text-align: center;
    
    margin-bottom: 25px;
  }
  
  &__nav {
    margin-bottom: 45px;
    
    &-list {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    &-item {
      &-btn {
        position: relative;
        z-index: 1;
        display: inline-block;
        
        cursor: pointer;
        
        font-size: 19px;
        line-height: 1.1;
        
        padding: 0 22px;
        
        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 10px;
          position: absolute;
          z-index: -1;
          left: 0;
          bottom: -3px;
          
          background: transparent;
          transition: background 0.3s;
        }
        
        &:hover:before {
          background: rgba(255, 224, 86, 0.5);
        }
      }
      
      &:first-child {
        .product-tabs__nav-item-btn {
          padding-left: 0;
        }
      }
      &:last-child {
        .product-tabs__nav-item-btn {
          padding-right: 0;
        }
      }
      &.active {
        .product-tabs__nav-item-btn:before {
          background: $secondary-color;
        }
      }
    }
  }
  &__content {
    &-item {
      display: none;
      
      &.active {
        display: block;
      }
    }
  }
}

@media (max-width: 767px) {
  .product-tabs {
    &__nav {
      &-item {
        text-align: center;
        
        &-btn {
          font-size: 16px;
        }
  
        &:first-child {
          .product-tabs__nav-item-btn {
            padding-left: 15px;
          }
        }
        &:last-child {
          .product-tabs__nav-item-btn {
            padding-right: 15px;
          }
        }
      }
    }
  }
}