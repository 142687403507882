.contact-us {
  margin: 40px 0 30px;
  
  &__title {
    margin-bottom: 45px;
  }
  &__form {
    .form-group {
      &__item {
        margin-bottom: 45px;
        
        input[type="text"], textarea {
          display: block;
          width: 100%;
        }
        textarea {
          min-height: 87px;
          max-height: 87px;
          max-width: 100%;
          min-width: 100%;
          
          border-right: 2px solid #cccccc;
        }
      }
    }
    
    &-nav {
      &:before, &:after {
        content: "";
        display: table;
        clear: both;
      }
      &-policy {
        float: right;
        
        font-size: 14px;
        line-height: 1.3;
        color: #cccccc;
        
        margin-top: 12px;
        
        a {
          text-decoration: underline;
          
          &:hover {
            text-decoration: none;
          }
        }
      }
      &-send {
        float: right;
        margin-left: 15px;
        
        .btn {
          font-size: 18px;
          line-height: 3.1;
  
          box-shadow: 6px 6px 4px 0px rgba(0, 0, 0, 0.35);
          
          padding: 0 60px;
        }
      }
    }
  }
}