.calc-prod {
  margin: 30px 0 35px;
  
  &__main {
    &.hide {
      display: none;
    }
    
    border: 52px solid $secondary-color;
    border-left: none;
    
    text-align: center;
    
    padding: 25px 25px 0 80px;
    
    &-title {
      margin-bottom: 15px;
    }
    &-start {
      margin-bottom: -24px;
      
      .btn {
        font-size: 18px;
        
        padding: 0 70px;
        
        box-shadow: 6px 6px 4px 0px rgba(0, 0, 0, 0.35);
      }
    }
    h3 {
      font-size: 22px;
      margin-bottom: 20px;
    }
    ul {
      display: inline-block;
      text-align: left;
      
      margin-bottom: 20px;
      
      li {
        position: relative;
        display: block;
        
        font-size: 22px;
        line-height: 1.1;
        color: #000000;
        
        padding: 0 0 0 20px;
        margin: 1px 0;
        
        &:before {
          position: absolute;
          left: 0;
          top: 50%;
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          
          transform: translateY(-50%);
          
          background: $primary-color;
        }
      }
    }
  }
  
  &__question {
      display: none;
      
      &.show {
        display: block;
      }
      
      &-list {
        &.no-refresh {
          .owl-stage {
            .owl-item {
              &:first-child {
                .calc-prod__question-item {
                  overflow: visible;
                  height: auto;
  
                  padding: 30px 30px 0 30px;
                }
              }
            }
          }
          
          .calc-prod__question {
            &-item {
              overflow: hidden;
              height: 0px;
              
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    
    &-attention {
      background: $secondary-color;
      
      padding: 10px 25% 10px 16.02%;
      
      &-inner {
        border: 1px dashed $primary-color;
        
        font-size: 16px;
        line-height: 1.2;
        
        padding: 4px 6px;
      }
    }
    &-cont {
      display: flex;
      flex-wrap: wrap;
      padding: 13px 0 0 16.02%;
  
      border-right: 52px solid #FFE056;
      border-bottom: 52px solid #FFE056;;
    }
    &-nav {
      position: relative;
      
      margin-bottom: -50px;
    }
    &-dot {
      width: 4px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
  
      padding: 0 14px;
  
      &:before {
        position: absolute;
        left: 50%;
        top: 0;
        content: "";
        display: block;
        width: 4px;
        height: 100%;
    
        transform: translateX(-50%);
    
        background: $secondary-color;
      }
      
      &-item {
        position: relative;
        display: block;
        width: 28px;
        
        background: $secondary-color;
        border-radius: 100%;
        box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.35);
        
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: $primary-color;
        
        transition: background 0.3s, color 0.3s;
        
        &.active {
          background: $primary-color;
          color: $secondary-color;
        }
      }
    }
    &-list {
      overflow: hidden;
      
      max-width: 90%;
    }
    &-item {
      padding: 30px 30px 0 30px;
      
      &-title {
        font-size: 23px;
        line-height: 1;
        
        margin: 0 0 15px 10px;
      }
      &-form {
        &-item {
          margin: 3px 0;
        }
      }
    }
    &-panel {
      text-align: right;
      width: 100%;
      
      .btn {
        font-size: 18px;
        padding: 0 70px;
        box-shadow: 6px 6px 4px 0px rgba(0, 0, 0, 0.35);
  
        margin-bottom: -24px;
        margin-right: 40px;
      }
    }
  }
  
  &__finish {
    display: none;
    border: 52px solid $secondary-color;
    border-left: none;
  
    padding: 25px 25px 0 22px;
    
    &.show {
      display: block;
    }
    &-title {
      font-size: 27px;
      
      margin-bottom: 12px;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    &-attention {
      width: 36%;
      
      border: 2px dashed $primary-color;
      background: $secondary-color;
      
      padding: 10px;
      
      p {
        font-size: 14px;
        line-height: 1.2;
        color: $primary-color;
        
        margin-bottom: 5px;
        
        &:last-child {
          margin-bottom: 0;
        }
        
        small {
          font-size: 12px;
        }
      }
    }
    &-form {
      position: relative;
      width: 58%;
      
      &-photo {
        width: 50%;
        position: absolute;
        right: 0;
        top: 50%;
        
        transform: translate3d(25%, -90%, 0);
        
        img {
          display: block;
          width: auto;
          max-width: 100%;
        }
      }
      
      &-title {
        font-size: 15px;
        font-weight: 600;
        line-height: 1.2;
        
        margin-bottom: 12px;
      }
  
      form {
        .form-group {
          &__item {
            text-align: right;
            
            margin-bottom: 15px;
            
            &--policy {
              color: #626262;
              
              margin: 10px 45px 10px 0;
            }
            
            input {
              display: block;
              width: 100%;
              max-width: 160px;
              
              text-align: left;
            }
  
            .btn {
              font-size: 18px;
              padding: 0 70px;
              box-shadow: 6px 6px 4px 0px rgba(0, 0, 0, 0.35);
    
              margin-right: 40px;
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .calc-prod {
    &__main {
      &-start {
        .btn {
          font-size: 16px;
          
          padding: 0 60px;
        }
      }
      h3 {
        font-size: 20px;
      }
      ul {
        li {
          font-size: 20px;
          line-height: 1.1;
          color: #000000;
          }
        }
    }
    
    &__question {
      &-attention {
        padding: 10px 25% 10px 5%;
        
        &-inner {
          font-size: 15px;
        }
      }
      &-cont {
        padding: 13px 0 0 8%;
      }
      &-item {
        &-title {
          font-size: 20px;
          
          margin-left: 0;
        }
      }
      &-panel {
        .btn {
          font-size: 16px;
          padding: 0 60px;
        }
      }
    }
    
    &__finish {
      &-title {
        font-size: 23px;
      }
      &-attention {
        p {
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .calc-prod {
    &__main {
      border: 30px solid #FFE056;
      border-left: none;
      padding: 25px 20px 0 20px;
      
      &-title {
        .title-border__text {
          font-size: 13px;
          text-transform: uppercase;
          
          &:before {
            height: 7px;
          }
        }
      }
      
      &-start {
        .btn {
          font-size: 15px;
          
          padding: 0 40px;
        }
      }
      h3 {
        font-size: 15px;
      }
      ul {
        li {
          font-size: 14px;
          
          &:before {
            height: 8px;
            width: 8px;
          }
        }
      }
    }
    
    &__question {
      &-attention {
        padding: 10px 30px 10px 30px;
        
        &-inner {
          font-size: 14px;
        }
      }
      &-cont {
        padding: 13px 0 0 20px;
  
        border-right: 30px solid #FFE056;
        border-bottom: 30px solid #FFE056;
      }
      &-item {
        padding: 0px 30px 25px 30px;
        
        &-title {
          font-size: 16px;
          font-weight: 600;
          
          margin-left: 0;
        }
        &-form {
          &-item {
            margin-bottom: 7px;
            
            .checkbox-style__label {
              font-size: 16px;
            }
          }
        }
      }
      &-panel {
        .btn {
          font-size: 16px;
          padding: 0 40px;
        }
      }
    }
    
    &__finish {
      &-content {
        display: block;
      }
      &-title {
        font-size: 23px;
      }
      &-form {
        width: 100%;
        
        margin-top: 40px;
  
        form {
          .form-group {
            &__item {
              &--policy {
                color: #626262;
          
                margin: 10px 0px 10px 0;
              }
        
              .btn {
                font-size: 16px;
                padding: 0 40px;
          
                margin-right: 0px;
                margin-top: 15px;
              }
            }
          }
        }
      }
      &-attention {
        width: 100%;
        p {
          font-size: 13px;
        }
      }
    }
  }
}