.gallery {
  &__wrap {
    position: relative;

    &-photo {
      overflow: hidden;
      height: 450px;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
    &-label {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;

      text-align: right;

      background: rgba(42, 14, 43, 0.85);

      padding: 30px 0 75px;
    }
    &-text {
      position: relative;
      z-index: 5;

      display: inline-block;
      font-size: 90px;
      font-weight: 700;
      text-align: right;
      color: $light-color;

      padding: 0 40px 0 0;

      &:before {
        content: "";

        position: absolute;
        z-index: -1;
        left: 0;
        bottom: 10px;
        display: block;
        width: 100%;
        height: 25px;

        background: $primary-color;
      }
    }
    &-btn {
      margin: 25px 40px 0 0;

      text-align: right;

      .btn {
        cursor: pointer;

        min-width: 250px;
        font-size: 20px;

        padding: 0 20px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .gallery {
    &__wrap {
      &-text {
        font-size: 60px;

        padding: 0 40px 0 0;
      }
      &-btn {
        .btn {
          font-size: 18px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .gallery {
    &__wrap {
      &-label {
        padding: 15px 0 30px;
      }
      &-text {
        font-size: 45px;
      }
      &-btn {
        .btn {
          font-size: 16px;
        }
      }
    }
  }
}
