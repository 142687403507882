.special-offer {
  border: 20px solid #FFE056;
  border-left: none;

  padding: 15px 25px 5px 25px;
  margin-bottom: 25px;

  &__title {
    margin-bottom: 35px;
  }

  .form-group {
    &__item {
      margin-bottom: 20px;

      input {
        width: 100%;
        line-height: 42px;
      }

      button {
        width: 100%;
      }
    }
  }
}
