.pdf-viewer {
  background: #ffffff;
  box-shadow: 5px 5px 12px 0 rgba(0, 0, 0, 0.2);

  padding: 20px 40px;

  &.is-loaded {
    .spinner {
      display: none;
    }
  }

  &__reader {
    display: block;
    position: relative;
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    overflow: auto;
  }
}
