.product-card {
  display: flex;

  &__photo {
    flex: 0 0 45%;
    max-width: 45%;

    margin-right: 20px;

    img {
      display: block;
      width: 100%;

      cursor: pointer;
    }
  }

  &__info {
    flex: 1;
  }

  &__name {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.1;

    margin-bottom: 8px;
  }

  &__cost {
    font-size: 18px;
    line-height: 1.1;

    margin-bottom: 15px;
  }

  &__nav {
    display: flex;
    margin-bottom: 20px;

    &-item {
      width: 50%;

      &:first-child {
        margin-right: 7px;
      }

      &:last-child {
        margin-left: 7px;
      }

      .btn {
        display: block;
        width: 100%;

        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  &__desk {
    &-name {
      font-size: 18px;
      line-height: 1;

      margin-bottom: 7px;
    }

    &-item {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.1;

      border-top: 1px solid #cccccc;

      padding: 3px 0;

      &:first-child {
        border-top: none;
      }
    }
  }

  @media (max-width: 1199px) {
    &__photo {
      margin-right: 15px;
    }

    &__name {
      font-size: 18px;
    }

    &__cost {
      font-size: 16px;
    }

    &__nav {
      &-item {
        .btn {
          font-size: 15px;

          &__ico {
            max-width: 20px;

            margin-right: 0;
          }
        }
      }
    }

    &__desk {
      &-name {
        font-size: 16px;
      }

      &-item {
        font-size: 13px;
      }
    }
  }

  @media (max-width: 767px) {
    display: block;

    &__photo {
      float: left;

      margin: 0 0 20px 0;
    }
    &__info {
      width: auto;
    }
    &__name, &__cost, &__nav {
      margin-left: 47%;
    }
    &__nav {
      display: block;

      margin-top: 30px;

      &-item {
        width: 100%;

        margin: 0 0 15px 0 !important;
      }
    }
    &__desk {
      clear: both;

      &-name {
        font-size: 18px;
      }

      &-item {
        font-size: 15px;
      }
    }
  }
}
