.content {
  flex: 1 0 auto;

  padding: 33px 0 31px;

  &__aside {
    height: 100%;
    position: relative;
    z-index: 10;

    &-menu {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: auto;

      box-shadow: 5px 5px 12px 0 rgba(0, 0, 0, 0.2);

      &-list {
        padding: 10px 5px;

        li {
          border-top: 1px solid rgba(204, 204, 204, 0.4);

          &:first-child {
            border-top: none;
          }

          a {
            display: block;

            font-size: 14px;
            text-transform: uppercase;
            line-height: 50px;

            background: transparent;
            transition: color 0.2s, background 0.2s;

            padding: 0 10px 0 24px;
          }

          &:hover a {
            background: $primary-color;

            color: $secondary-color;
          }
        }
      }

    }
  }
  &__briefly {
    margin-bottom: 35px;

    &-made {
      font-size: 15px;
      font-weight: 300;
      text-align: right;
      line-height: 1.2;
      color: #cccccc;
    }
    h3 {
      font-weight: 500;

      margin-bottom: 4px;
    }
    p {
      font-size: 15px;
      line-height: 1.3;

      margin-bottom: 7px;
    }
    ul {
      display: block;
      margin-bottom: 7px;

      li {
        position: relative;
        display: block;

        font-size: 15px;
        line-height: 1.3;

        padding-left: 15px;
        margin-left: 5px;
        margin-bottom: 2px;

        &:before {
          position: absolute;
          left: 0;
          top: 50%;

          content: "";
          display: block;
          width: 7px;
          height: 7px;

          background: $secondary-color;

          transform: translateY(-40%);
        }
      }
    }

    dl {
      margin: 12px 0;

      dt {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.1;

        margin-bottom: 5px;
      }
      dd {
        font-size: 14px;
        line-height: 1.3;

        margin: 0 0 2px 10px;
      }
    }
  }
  &__video {
    margin-bottom: 40px;

    iframe {
      width: 100% !important;
    }
  }
  &__text {
    margin-bottom: 40px;

    h2, h3 {
      font-size: 17px;
      font-weight: 500;
      text-transform: uppercase;

      line-height: 1.2;

      margin-bottom: 13px;
    }
    p {
      font-size: 14px;
      line-height: 1.4;

      margin: 8px 0;
    }
    dl {
      margin: 12px 0;

      dt {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.1;

        margin-bottom: 5px;
      }
      dd {
        font-size: 14px;
        line-height: 1.3;

        margin: 0 0 2px 10px;
      }
    }
  }
  &__attention {
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
    color: #000000;

    background: #E6E6E6;

    padding: 15px 60px 15px 75px;
    margin: 25px -15px 25px -30px;
  }
}

@media (max-width: 1199px) {
  .content {
    &__aside {
      margin-right: -15px;

      &-menu {
        &-list {
          padding: 0 17px 0 5px;

          li {
            a {
              font-size: 14px;
              line-height: 40px;

              padding: 0 10px 0 10px;
            }
          }
        }

      }
    }

    &__briefly {
      p {
        font-size: 14px;
      }
      ul {
        li {
          font-size: 14px;
        }
      }

      dl {
        dt {
          font-size: 14px;
        }
        dd {
          font-size: 13px;
        }
      }
    }

    &__text {
      margin-bottom: 40px;

      h2, h3 {
        font-size: 17px;
        font-weight: 500;
        text-transform: uppercase;

        line-height: 1.2;

        margin-bottom: 13px;
      }
      p {
        font-size: 14px;
        line-height: 1.4;

        margin: 8px 0;
      }
      dl {
        margin: 12px 0;

        dt {
          font-size: 15px;
          font-weight: 500;
          line-height: 1.1;

          margin-bottom: 5px;
        }
        dd {
          font-size: 14px;
          line-height: 1.3;

          margin: 0 0 2px 10px;
        }
      }
    }
    &__attention {
      font-size: 14px;

      margin: 25px -15px 25px -15px;
    }
  }
}
