.social-nav {
  display: block;
  
  li {
    display: inline-block;
    vertical-align: middle;
    
    margin-right: 31px;
    
    &:last-child {
      margin-right: 0;
    }
    
    a {
      display: block;
    }
    
    svg {
      display: block;
      width: 100%;
      max-width: 45px;
      max-height: 43px;
      
      path {
        fill: #fff;
        opacity: 0.5;
        
        transition: opacity 0.3s;
      }
    }
    
    &:hover svg path {
      opacity: 1;
    }
  }
}