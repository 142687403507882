$base-font: 'Roboto', sans-serif;
$primary-color: #2A0E2B;
$secondary-color: #FFE056;
$dark-color: #000000;
$light-color: #F7F7F7;

html {
  height: 100%;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  height: 100%;
  position: relative;

  background-color: $light-color;
  font-family: $base-font;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  color: $dark-color;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;

  &.no-scroll {
    overflow-y: scroll;
    position: fixed;
    width: 100%;

    &--delete {
      overflow: hidden;
    }
  }
  &.delete-scroll {
    overflow: hidden;
  }
}

.wrapper {
  position: relative;
  background: $light-color;
  min-width: 320px !important;

  display: flex;
  flex-direction: column;
  min-height: 100%;

  @media (max-width: 991px) {
    padding-top: 68px;
  }
}

.main-overlay {
  display: none;
  position: fixed;
  left: 0;
  width:100%;
  height: 100%;
  top: 0;
  z-index: 80;
  background: rgba(42, 14, 43, 0.9);
  cursor: pointer;

  transition: background .3s;

  &.show {
    display: block;
  }
  &:hover {
    background: rgba(42, 14, 43, 0.7);
  }
}

a {
  text-decoration: none;
  color: inherit;
}
b {
  font-weight: 700;
}

table {
  width: 100%;
  margin-bottom: 22px;

  tr {
    td {
      font-size: 13px;
      text-align: center;

      border-right: 1px solid #cccccc;

      padding: 7px 10px;

      &:last-child {
        border: none;
      }
    }
  }

  thead {
    background: #FBF3D7;

    tr td {
      font-weight: 500;
    }
  }
  tbody {
    tr {
      background: #F7F7F7;
    }
    tr:nth-child(2n) {
      background: #F2F2F2;
    }
  }
}

.green {
  color: #00E676;
}
.violet {
  color: #834995;
}

.lazy, .owl-lazy {
  opacity: 0;
  transition: opacity .3s;

  &--onload {
    opacity: 1;
  }
}

.js-ajax-form {
  &--loading {
    button[type=submit] {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
