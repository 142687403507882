.catalog {
  &__tile {
    margin: 32px 0 10px;

    &-list {
      margin-right: -7px;

      &:before, &:after {
        content: "";
        display: table;
        clear: both;
      }
    }
    &-item {
      position: relative;
      float: left;
      height: 220px;

      padding: 0 7px 7px 0;

      &:hover {
        .catalog__tile {
          &-info {
            opacity: 1;
          }
        }
      }

      &--33 {
        width: 33.3333%;
      }
      &--40 {
        width: 40%;
      }
      &--50 {
        width: 50%;
      }
      &--60 {
        width: 60%;
      }
    }
    &-photo {
      position: relative;
      display: block;
      height: 100%;
      overflow: hidden;

      img {
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;

        object-fit: cover;
        object-position: top center;
      }

      &:before {
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;

        content: "";
        display: block;
        width: 100%;
        height: 100%;

        background: rgba(155, 155, 155, 0.72);
      }
    }
    &-info {
      display: flex;
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      right: 7px;
      bottom: 7px;

      opacity: 0;

      transition: opacity 0.3s;

      &-cont {
        text-align: center;

        margin: auto;
      }
    }
    &-title {
      font-size: 27px;
      text-transform: uppercase;
      line-height: 1.1;
      color: $primary-color;

      border-bottom: 1px solid $primary-color;

      padding: 0 7px 5px;
      margin-bottom: 25px;
    }
    &-more {
      .btn {
        font-size: 18px;

        padding: 0 30px;
      }
    }
  }
}

@media (max-width: 767px) {
  .catalog {
    &__tile {
      &-item {
        &--33 {
          width: 100%;
        }
        &--40 {
          width: 100%;
        }
        &--60 {
          width: 100%;
        }
      }

      &-info {
        background: rgba(255, 255, 255, 0.68);
        opacity: 1;
      }
    }
  }
}
