.checkbox-style {
  display: inline-block;
  padding: 0;
  margin: 0;

  input[type="radio"] {
    display: none !important;
  }

  &.error {
    .checkbox-style {
      &__label {
        input {
          border-color: #e62316;
        }
      }
    }
  }

  &__custom {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: 19px;
    height: 19px;

    border: 1px solid $primary-color;
    background: transparent;
    border-radius: 100%;

    transition: color 0.3s, background 0.3s;
  }
  &__label {
    display: inline-block;
    vertical-align: middle;

    font-size: 19px;
    line-height: 1.1;
    color: $primary-color;

    cursor: pointer;

    padding: 0 5px;

    input[type="text"] {
      display: inline-block;
      background: none;
      border: none;
      outline: none;

      font-size: 16px;
      border-bottom: 2px solid $primary-color;

      padding: 4px 0;
      margin: 0;
    }
  }

  &:hover {
    .text-radio {
      &__label {
        color: $primary-color;
      }
    }
  }

  input[type="radio"]:checked + &__custom {
    background: $secondary-color;
  }
}
