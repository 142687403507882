.share {
  margin: 35px 0;
  
  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    li {
      opacity: 1;
      
      transition: opacity 0.3s;
      
      &:hover {
        opacity: 0.7;
      }
      
      a {
        svg {
          display: block;
          height: 42px;
        }
      }
      
      &.fb svg path {
        fill: #0071BB;
      }
      &.vk svg path {
        fill: #2E3191;
      }
      &.yt svg path {
        fill: #C0272D;
      }
      &.insta svg path {
        fill: #42210B;
      }
    }
  }
}