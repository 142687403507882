.btn {
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;

  border: none;
  outline: none;
  line-height: 1;
  cursor: pointer;
  background: transparent;
  text-align: center;

  font-family: Roboto, sans-serif !important;
  transition: background 0.3s, color 0.3s, border-color 0.3s;

  padding: 0;
  margin: 0;

  &__ico {
    display: inline-block;
    vertical-align: middle;

    max-width: 25px;
    margin-right: 5px;

    svg {
      display: block;
      width:100%;

      path {
        fill: $primary-color;

        transition: fill 0.3s;
      }
    }
  }
  &__text {
    display: inline-block;
    vertical-align: middle;
  }

  &--yellow {
    font-size: inherit;
    line-height: 2.3;
    color: $dark-color;

    background: $secondary-color;

    padding: 0 15px;

    &:hover {
      background: #ffd000;
    }
  }

  &--yellow-border {
    font-size: inherit;
    line-height: 2.8;
    color: $secondary-color;

    border: 1px solid $secondary-color;

    padding: 0 50px;

    &:hover {

      background: $secondary-color;
      color: $light-color;
    }
  }

  &--dark-border {
    font-size: inherit;
    line-height: 2.3;
    color: $dark-color;

    border: 1px solid $dark-color;

    padding: 0 10px;

    &:hover {
      background: $dark-color;
      color: $light-color;

      .btn {
        &__ico {
          svg path {
            fill: #fff;
          }
        }
      }
    }
  }

  &--violet {
    font-size: inherit;
    line-height: 3;
    color: $secondary-color;

    background: $primary-color;

    padding: 0 15px;

    &:hover {
      background: #6B246C;
    }

    .btn {
      &__ico {
        svg path {
          fill: $secondary-color;
        }
      }
    }
  }

  &--white {
    font-size: inherit;
    line-height: 2.3;
    color: $dark-color;

    background: $light-color;

    padding: 0 15px;

    &:hover {
      background: $secondary-color;
      color: $dark-color;

      .btn {
        &__ico {
          svg path {
            fill: $dark-color;
          }
        }
      }
    }
  }

  &--stretch {
    width: 100%;
  }

  &--lg {
    font-size: 18px;
    font-weight: 400;
  }
}
