.combine {
  margin-bottom: 30px;
  
  &__title {
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
    
    margin-bottom: 45px;
  }
  &__nav {
    &-list {
      display: flex;
      justify-content: space-between;
    }
    &-item {
      position: relative;
      cursor: pointer;
      width: 18%;
      
      &-radio {
        text-align: center;
        
        margin-bottom: 15px;
      }
      &-photo {
        position: relative;
        
        border: 2px solid transparent;
        transition: border-color 0.3s;
        
        img {
          display: block;
          width: 100%;
        }
        
        &-zoom {
          position: absolute;
          z-index: 5;
          left: 50%;
          bottom: 10%;
  
          opacity: 0;
          transform: translateX(-50%);
          transition: opacity 0.5s;
          
          a {
            outline: none;
          }
          
          svg {
            display: block;
            height: 30px;
            
            path {
              fill: rgba(164, 164, 164, 0.8);
              transition: fill 0.3s;
            }
          }
          
          &:hover svg path {
            fill: $secondary-color;
          }
        }
      }
      &-arrow {
        opacity: 0;
        transition: opacity 0.3s;
        
        position: absolute;
        left: 50%;
        top: 100%;
        width: 31px;
        
        transform: translate3d(-50%, 0, 0) rotate(-45deg);
        
        background: #ffe056;
        box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.3);
        
        margin-top: 15px;
        
        &:before {
          content: "";
          display: block;
          
          padding-top: 100%;
        }
      }
      
      &:hover {
        .combine__nav-item {
          &-photo {
            border-color: $secondary-color;
            
            &-zoom {
              opacity: 1;
            }
          }
        }
      }
  
      &.active {
        .combine__nav-item {
          &-photo {
            border-color: $secondary-color;
          }
          &-arrow {
            opacity: 1;
          }
        }
      }
    }
  }
  
  &__content {
    position: relative;
    margin-top: 30px;
    
    &-attention {
      position: relative;
      background: #F2F2F2;
      
      padding: 10px 120px 10px 45px;
      
      &-item {
        font-size: 13px;
        line-height: 1.2;
        
        margin-top: 10px;
        
        &:first-child {
          margin-top: 0;
        }
      }
      
      &-ico {
        position: absolute;
        right: 20px;
        top: 0;
        height: 100%;
        
        display: flex;
        align-items: center;
        
        svg {
          height: 80%;
          
          path,
          circle{
            fill: #fff;
          }
        }
      }
    }
    
    &-item {
      display: none;
      
      &.active {
        display: block;
      }
    }
    
    &-desk {
      border: 3px solid #ffe056;
      background: #F2F2F2;
      border-radius: 7px;
      
      font-size: 15px;
      
      box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
      
      padding: 12px 15px 15px;
      margin-bottom: 20px;
      
      h3 {
        font-weight: 500;
        line-height: 1.1;
        text-transform: uppercase;
        
        margin-bottom: 8px;
      }
      p {
        line-height: 1.3;
        
        margin-top: 2px;
      }
    }
    
    &-title {
      font-size: 14px;
      line-height: 1.2;
      text-transform: uppercase;
      text-align: center;
      
      margin: 20px 0 15px;
    }
  }
  
  &__order {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    margin: 20px 0 70px;
    
    &-item {
      margin-left: 15px;
  
      .btn {
        display: block;
        width: 100%;
        min-width: 228px;
    
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
  
  &__calc {
    display: flex;
  
    &-item {
      background: #F2F2F2;
    
      padding: 30px 27px 15px;
    
      &:nth-child(2n) {
        background: transparent;
      }
    }
  
    &-title {
      font-size: 17px;
      line-height: 1;
      text-align: center;
    
      margin-bottom: 20px;
    }
  
    .form-group {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    
      &__item {
        margin-bottom: 20px;
      
        width: 50%;
      
        text-align: center;
      
        .checkbox-style {
          &__custom {
            width: 16px;
            height: 15px;
          }
          &__label {
            font-size: 14px;
          }
        }
      
        &--numb {
          width: 100%;
        
          input {
            display: inline-block;
            width: 100%;
            max-width: 70px;
          
            text-align: center;
            font-size: 27px;
          
            border-color: $primary-color;
          
            border-radius: 50px;
          }
        
          .form-group__item-info {
            font-size: 14px;
          
            margin-top: 15px;
          }
        
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .combine {
    &__title {
      font-size: 17px;
      font-weight: 600;
    
      margin-bottom: 45px;
    }
    
    &__nav {
      &-item {
        width: 20%;
        
        &-radio {
          margin-bottom: 10px;
        }
        &-photo {
          &-zoom {;
            bottom: 5%;
            
            opacity: 1;
            
            svg {
              height: 24px;
            }
          }
        }
      }
      
    }
  
    &__content {
      &-attention {
        margin-bottom: 25px;
      }
    }
  }
}