.contact-info {
  &__list {
    display: flex;
  }
  &__item {
    box-sizing: border-box;
    width: 25%;
    
    border-top: 44px solid #FEEFBC;
    background: #FBF3D7;
    
    padding: 0 14px;
    
    &:nth-child(2n) {
      border-top: 44px solid #E6E6E6;
      background: #F1F1F1;
    }
    
    &-wr {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      
      padding: 25px 0;
    }
    
    &-content {
      padding: 0 15px;
    }
    
    &-ico {
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      width: 40px;
      
      background: #ffffff;
      border-radius: 100px;
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.36);
      
      transform: translateY(-50%);
      
      &:before {
        content: "";
        display: block;
        
        padding-top: 100%;
      }
      
      svg {
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 2;
        display: block;
        height: 55%;
        
        transform: translate3d(-50%, -50%, 0);
        
        path {
          fill: #FFE147;
        }
      }
    }
    
    &-text {
      font-size: 14px;
      line-height: 1.4;
      color: #2D0527;
      
      margin: 13px 0;
      
      i {
        font-size: 12px;
        font-style: italic;
        color: #cccccc;
      }
    }
  }
}

@media (max-width: 1199px) {
  .contact-info {
    &__item {
      padding: 0 12px;
      
      &-content {
        padding: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .contact-info {
    &__list {
      flex-wrap: wrap;
    }
    &__item {
      width: 50%;
      padding: 0 15px;
      
      &-content {
        padding: 0 15px;
      }
    }
  }
}