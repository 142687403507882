.title-border{
  display: block;
  text-align: center;
  
  margin-bottom: 15px;
  
  &__text {
    position: relative;
    z-index: 1;
    display: inline-block;
    
    font-size: 20px;
    line-height: 1.1;
    
    padding: 0 22px;
    
    &:before  {
      content: "";
      display: block;
      width: 100%;
      height: 10px;
      
      position: absolute;
      z-index: -1;
      left: 0;
      bottom: -3px;
      
      background: $secondary-color;
    }
  }
}

@media (max-width: 767px) {
  .title-border{
    &__text {
      font-size: 18px;
    }
  }
}