.style-scroll {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&__content {
		position: absolute;
  		overflow: scroll;
  		overflow-x: hidden;
  		outline: none;
  		top: 0;
  		right: 0;
  		bottom: 0;
  		left: 0;

  		padding-right: 20px;
  		margin-right: -20px;

  		&::-webkit-scrollbar {
  			display: none;			
  		}
	}
	&__panel {
	  	background: #cccccc;
	  	position: absolute;
	  	width: 1px;
	  	right: 6px;
	  	top: 0;
	  	bottom: 0;
	}
	&__slider {
	  	background: $primary-color;
	  	position: relative;
	  	margin: 0 -2px;
		
		border-radius: 100px;
	}

	&.no-scrollbar {
		overflow: visible;

		.style-scroll {
			min-height: auto;

			&__content {
				position: static;
				overflow: visible;

				padding: 0;
				margin: 0;
			}
		}
	}
}

.has-scrollbar > .style-scroll__content::-webkit-scrollbar {
  display: block;
}