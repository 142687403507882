.dialog {
  max-width: 400px;
  padding: 50px 40px;

  &__title {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;

    margin-bottom: 25px;
  }

  &__content {
    font-size: 15px;
    line-height: 1.2;
    text-align: center;

    p:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__form {
    margin: 40px 0 0;

    input[type="text"] {
      display: block;
      width: 100%;

      padding-bottom: 8px;
    }

    .btn {
      display: block;
      width: 100%;
    }

    .form-group__item {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__photo {
    img {
      display: block;
      width: 100%;

      cursor: all-scroll;
    }
  }

  &#dialog-product-gallery {
    max-width: 966px;
    padding: 52px 28px 30px;
  }

  &#dialog-product-order {
    max-width: 900px;

    padding: 30px;

    .dialog {
      &__wrapper {
        display: flex;
        align-items: center;
      }
      &__aside {
        flex: 0 0 430px;
        max-width: 430px;
        width: 100%;
      }
      &__body {
        flex: 1;

        padding-left: 40px;
      }
    }
  }
}
