.header {
	&__top {
		background: $primary-color;

		padding: 10px 0 15px;
	}
	&__bottom {
    position: sticky;
    top: 0;
		z-index: 50;

		background: $light-color;

		box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.5);
	}
	&__mobile {
		position: fixed;
		z-index: 50;
		left: 0;
		top: 0;
		width: 100%;

		background: $primary-color;

		padding: 15px 0;

		&-logo {
			a {
				display: block;

				img {
					width: 60px;
				}
			}
		}
		&-phone {
			cursor: pointer;

			svg {
				width: 34px;
				max-height: 34px;

				path {
					fill: $secondary-color;
					stroke-width: 0.8px;
					stroke: $secondary-color;
				}
			}
		}

		&-row {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
	&__nav {
		display: flex;
		justify-content: space-between;

		width: 100%;

		li {
			display: block;

			a {
				overflow: hidden;
				position: relative;
				display: block;

				font-size: 18px;
				text-align: center;
				text-transform: uppercase;
				line-height: 60px;
				color: $dark-color;

				padding: 0 26px;

				&:before {
					position: absolute;
					left: 0;
					bottom: 0;

					content: "";
					display: block;
					width: 100%;
					height: 12px;

					background: transparent;
					transform: translateY(100%);

					transition: background 0.3s, transform 0.3s;
				}
			}
			&:first-child a {
				padding-left: 0;
			}
			&:last-child a {
				padding-right: 0;
			}

			&:hover a:before, &.active a:before {
				background: $secondary-color;
				transform: translateY(0);
			}
		}
	}
	&__delivery {
		font-size: 17px;
		text-align: left;
		line-height: 1.2;
		color: $light-color;
	}
	&__callback {
		text-align: right;

		.btn {
			font-size: 18px;
		}
	}
	&__phones {
		position: absolute;
		right: 0;
		bottom: 0;
		transform: translateY(100%);

		background: $light-color;
		box-shadow: -2px 12px 20px 0px rgba(0, 0, 0, 0.2);
		border-radius: 0px 0px 0px 15px;

		padding: 0 10px 0 13px;


		&-item {
			padding-left: 18px;
			margin: 0 8px 16px 0;

			&:first-child {
				padding-top: 6px;
			}

			&-num {
				font-size: 15px;
				line-height: 1;
			}
			&-desk {
				font-size: 12px;

				margin-top: 3px;
			}
		}

		&-main {
			.header__phones-item {
				cursor: pointer;

				position: relative;
				padding-top: 0;
				margin: 10px 8px 10px 0;

				&:before {
					content: "";
					border: 5px solid transparent;
					border-top: 8px solid $primary-color;

					position: absolute;
					left: 0;
					top: 4%;

					transform: rotate(0deg);
					transition: transform 0.3s;
				}
			}
		}
		&-cont {
			display: none;
		}

		&:hover {
			.header__phones {
				&-main {
					.header__phones-item:before {
						transform: rotate(-180deg);
					}
				}
				&-cont {
					display: block;
				}
			}
		}
	}
}

@media (max-width: 1199px) {
	.header {
		&__nav {
			li {
				a {
					font-size: 16px;
				}
			}
		}
		&__delivery {
			font-size: 14px;
		}
		&__callback {
			.btn {
				font-size: 17px;
			}
		}
	}
}

@media (max-width: 991px) {
	.header {
		padding-top: 81px;
	}
}
