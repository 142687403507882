.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  
  transform: translateX(-100%);
  transition: transform 0.5s;
  will-change: transform;
  
  background: #ffffff;
  
  padding: 100px 0 30px;
  
  &.active {
    transform: translateX(0%);
  }
  
  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    
    width: 100%;
    max-width: 500px;
    min-height: 100%;
    
    padding: 0 20px;
    margin: 0 auto;
  }
  &__list {
    text-align: center;
    
    &-item {
      padding: 10px 0;
      
      font-size: 25px;
      font-weight: 500;
  
      &.active>a:before {
          background: $secondary-color;
      }
      
      a {
        position: relative;
        z-index: 1;
        display: inline-block;
        
        line-height: 1;
        padding: 0 7px 3px;
        
        &:before {
          z-index: -1;
          position: absolute;
          left: 0;
          bottom: 0;
          content: "";
          display: block;
          width: 100%;
          height: 10px;
          
          background: transparent;
          transition: background 0.3s;
        }
  
        &:hover:before{
          background: $secondary-color;
        }
        
      }
  
      &-sub {
        margin-top: 15px;
    
        &-item {
          font-size: 20px;
          
          padding: 5px 0;
          
          a {
            font-weight: 400;
          }
        }
      }
    }
  }
}