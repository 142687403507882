.product {
  &__item {
    border-top: 1px dashed #cccccc;
    padding: 30px 0 22px;

    &:first-child {
      padding-top: 0;
      border-top: none;
    }
  }

}
