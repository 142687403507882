.form-group {
  &__item {
    margin-bottom: 10px;
    
    input, textarea {
      font-family: "Roboto", sans-serif !important;
      
      outline: none;
      background: transparent;
      border: none;
      border-bottom: 2px solid #cccccc;
      
      transition: border-color 0.3s;
      
      font-size: 16px;
      line-height: 1.2;
      
      padding: 0 5px;
      
      &.error {
        border-color: #e62316;
      }
    }
    
    &--policy {
      margin-top: 30px;
      text-align: center;
      
      font-size: 10px;
      line-height: 1.3;
      color: #cccccc;
      
      a {
        display: block;
        text-decoration: underline;
        
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}