.review-card {
  overflow: hidden;
  height: 300px;

  border: 3px solid $secondary-color;
  cursor: pointer;

  &__photo {
    display: block;
    width: 100%;
    height: 100%;

    img {
      display: block;
      width: 100%;
      height: 100%;

      object-fit: cover;
      object-position: top;
      transform: scale(1);
      transition: opacity 0.3s, transform 0.3s !important;
    }
  }

  &__info {
    position: absolute;
    left: 3px;
    bottom: 3px;
    right: 3px;
    min-height: 30%;

    padding: 20px 10px 10px 0;
    background: rgba(255, 255, 255, 0.88);

    &-title {
      position: relative;
      z-index: 1;
      display: inline-block;
      text-align: left;

      padding: 0 19px 0 8px;
      margin-bottom: 30px;

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 10px;

        position: absolute;
        z-index: -1;
        left: 0;
        bottom: -3px;

        background: $secondary-color;
      }

      &-ico {
        display: inline-block;
        vertical-align: middle;

        margin-right: 10px;

        @media (max-width: 1199px) {
          display: none;
        }

        svg {
          display: block;
          height: 20px;
          fill: none;
          stroke: #2b1722;
          stroke-miterlimit: 10;
          stroke-width: 2px;
        }
      }

      &-text {
        display: inline-block;
        vertical-align: middle;

        font-size: 23px;
        line-height: 1;
        color: $dark-color;
      }
    }
  }

  &__desc {
    text-align: right;

    &-title {
      font-size: 18px;
      font-weight: 400;

      margin-bottom: 2px;
    }

    a {
      color: #0d95e8;
      text-decoration: underline;
    }
  }

  &:hover {
    .review-card {
      &__photo {
        img {
          opacity: 0.9;
          transform: scale(1.1);
        }
      }
    }
  }
}

.review-prod {
  margin: 35px 0 45px;

  &__header {
    &-title {
      margin-bottom: 15px;
    }
  }

  &__title {
    .title-border__text {
      font-size: 16px;

      @media (max-width: 1199px) {
        font-size: 20px;
      }
    }

    margin: 0 0 40px;
  }

  &__carousel {
    position: relative;
    padding: 0 60px;
    margin: 0 60px;

    @media (max-width: 1199px) {
      padding: 0 30px;
    }

    @media (max-width: 767px) {
      padding: 0 65px;
      margin: 0;
    }

    &.owl-carousel {
      width: auto;

      .owl-nav {
        .owl-prev, .owl-next {
          position: absolute;
          top: 50%;
          overflow: hidden;
          display: block;
          height: 0;
          width: 0;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;

          text-indent: -999999px;

          opacity: 1;
          outline: none;
          transform: translateY(-50%);
          transition: opacity .3s;

          &.disabled {
            opacity: 0;
          }
        }

        .owl-next {
          right: 0;
          border-left: 12px solid $primary-color;
        }

        .owl-prev {
          left: 0;
          border-right: 12px solid $primary-color;
        }
      }
    }
  }

  &__text {
    font-size: 14px;
    line-height: 1.2;

    padding: 40px 25px 0;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-item {
      flex: 0 0 47%;
      max-width: 47%;

      position: relative;

      margin-top: 30px;

      @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    &__info {
      right: 0;
      bottom: 0;
      left: 0;

      &-title-text {
        font-size: 19px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .review-prod {
    &__title {
      .title-border__text {
      }
    }
  }
}
