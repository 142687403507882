.menu-trigger {
    position: relative;
	width: 30px;
	height: 30px;

    cursor: pointer;

    &__burger {
        position: relative;
        width: 100%;
        height: 100%;
    }
    &__line {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 3px;

        transform: translate(-0,-50%) rotate(0deg);
        background-color: $secondary-color;
        will-change: transform;

        transition: transform .3s,opacity .2s,top .3s;
        opacity: 1;

        &:first-of-type {
            top: 20%
        }
        &:last-of-type {
            top: 80%
        } 
    }

    &.active &__line {
        top: 50%;
        transform: translate(50%,-50%) rotate(0deg);
        opacity: 0;

        &:first-of-type {
            top: 50%;
            transform: translate(0,-50%) rotate(-45deg);
            opacity: 1;
        }
        &:last-of-type {
            top: 50%;
            transform: translate(0,-50%) rotate(45deg);
            opacity: 1;
        } 
    }
}