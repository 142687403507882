.spinner {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 30px;

  &__loader {
    position: relative;
    width: 70px;
    height: 70px;

    background: linear-gradient(to right, #FFE056 10%, rgba(255, 255, 255, 0) 42%);
    border-radius: 50%;

    transform: translateZ(0);
    animation: spinner-keyframe 1.4s infinite linear;

    &:before, &:after {
      content: '';

      position: absolute;
      top: 0;
      left: 0;
    }

    &:before {
      width: 50%;
      height: 50%;

      background: #FFE056;
      border-radius: 100% 0 0 0;
    }
    &:after {
      bottom: 0;
      right: 0;
      width: 75%;
      height: 75%;

      background: #ffffff;
      border-radius: 50%;

      margin: auto;
    }
  }
}

@keyframes spinner-keyframe {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
