.main-logo {
  max-width: 260px;

  a {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    img {
      display: block;
      width: 100%;
    }
  }
}
