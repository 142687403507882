.product-gallery {
  &__body {
    display: flex;
    flex-wrap: wrap;
  }

  &__preview {
    position: relative;
    flex: 0 0 44%;
    max-width: 44%;

    margin-right: 20px;

    img {
      display: block;
      width: 100%;
    }
  }

  &__offer {
    flex: 1;
  }

  &__thumbnails {
    padding: 0 40px;
    margin-top: 30px;

    &.owl-carousel {
      width: auto;

      .owl-item {
        cursor: pointer;
        opacity: 1;
        transition: opacity .3s;

        &:hover {
          opacity: 0.8;
        }
      }
      .owl-nav {
        .owl-prev, .owl-next {
          position: absolute;
          top: 50%;
          overflow: hidden;
          display: block;
          height: 40px;
          width: 40px;

          text-indent: -999999px;
          outline: none;

          transform: translateY(-50%) rotate(45deg);

          &.disabled {
            opacity: 0;
          }
        }
        .owl-next {
          right: 0;

          border-right: 2px solid #2A0E2B;
          border-top: 2px solid #2A0E2B;
        }
        .owl-prev {
          left: 0;
          border-left: 2px solid #2A0E2B;
          border-bottom: 2px solid #2A0E2B;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .product-popup {
    &__view {
      &-name {
        font-size: 18px;
      }
      &-cost {
        font-size: 16px;
      }
      &-nav {
        &-item {
          .btn {
            font-size: 15px;

            &__ico {
              max-width: 20px;

              margin-right: 0;
            }
          }
        }
      }
      &-desk {
        &-name {
          font-size: 16px;
        }
        &-item {
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .product-popup {
    &__view {
      display: block;

      &-photo {
        min-height: auto;

        float: left;

        margin: 0 0 20px 0;
      }
      &-info {
        width: auto;
      }
      &-name, &-cost, &-nav {
        margin-left: 47%;
      }
      &-nav {
        display: block;

        margin-top: 30px;

        &-item {
          width: 100%;

          margin: 0 0 15px 0 !important;
        }
      }
      &-desk {
        clear: both;

        &-name {
          font-size: 18px;
        }
        &-item {
          font-size: 15px;
        }
      }
    }
  }
}
