.footer {
  flex: 0 0 auto;

  background: $primary-color;

  padding: 20px 0;

  &__law {
    font-size: 17px;
    line-height: 1.3;
    color: $light-color;

    margin-bottom: 40px;
  }
  &__delivery {
    font-size: 17px;
    line-height: 1.3;
    color: $light-color;
  }
  &__callback {
    text-align: center;

    .btn {
      width: 100%;

      font-size: 18px;

      padding: 0;
    }
  }
  &__policy {
    text-align: center;

    margin-bottom: 3px;

    a {
      font-size: 14px;
      line-height: 1.2;
      text-decoration: underline;
      color: $light-color;

      &:hover {
        text-decoration: none;
      }
    }
  }
  &__mail {
    text-align: center;

    margin: 40px 0 23px;

    a {
      font-size: 25px;
      color: $light-color;
    }
  }
  &__phone {
    &-item {
      font-size: 18px;
      line-height: 1.3;
      color: $light-color;

      margin-top: 17px;
    }
  }

  .main-logo {
    margin-bottom: 23px;

    &__ico {
      max-width: 88px;
    }
    &__name {
      font-size: 28px;

      margin-bottom: 14px;
    }
    &__desk {
      font-size: 15px;
    }
  }
}

@media (max-width: 1199px) {
  .footer {
    &__law {
      font-size: 13px;
    }
    &__delivery {
      font-size: 14px;
    }
    &__callback {
      .btn {
        font-size: 16px;
      }
    }
    &__mail {
      a {
        font-size: 23px;
      }
    }
    &__phone {
      &-item {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 991px) {
  .footer {
    &__law {
      font-size: 12px;

      margin-bottom: 30px;
    }
    &__delivery {
      font-size: 12px;
    }
    &__callback {
      .btn {
        font-size: 12px;
      }
    }
    &__policy {

      a {
        font-size: 10px;
        }
      }
    &__mail {
      a {
        font-size: 16px;
      }
    }
    &__phone {
      margin-left: -15px;
      margin-right: -5px;

      &-item {
        font-size: 12px;
      }
    }
    .social-nav {
      li {
        margin-right: 20px;

        svg {
          max-width: 36px;
          max-height: 36px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .footer {
    .flex-row--bottom {
      align-items: normal;
    }
    &__law {
      font-size: 13px;
    }
    &__phone {
      margin-left: 0;
      margin-right: 0;

      &-item {
        font-size: 14px;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    .social-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 30px;

      li {
        margin-right: 0;

        svg {
          max-width: 41px;
          max-height: 41px;
        }
      }
    }
  }

}
