.photo-gallery {
  margin: 20px 0 50px 0;
  
  &__title {
    margin-bottom: 35px;
  }
  
  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    
    margin: 0 -2px;
  }
  &__item {
    width: 33.3333%;
    
    opacity: 1;
    transition: opacity 0.3s;
    
    padding: 2px;
    
    &:hover {
      opacity: 0.8;
    }
    
    a, img {
      outline: none;
      display: block;
      width: 100%;
    }
  }
}