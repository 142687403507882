.call-phones {
  &__item {
    width: 100%;
    padding: 10px 40px;

    border-top: 1px solid #cccccc;

    &:first-child {
      border-top: none;
    }

    &-num {
      font-size: 20px;
      line-height: 1;

      a {
        line-height: 1;
        color: #1b5df7;

        border-bottom: 1px solid #1b5df7;
        transition: border-color 0.3s;

        &:hover {
          border-color: transparent;
        }
      }
    }
    &-desk {
      font-size: 14px;

      margin-top: 6px;
    }
  }
}
