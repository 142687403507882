.selection-product {
  margin-bottom: 15px;

  &__title {
    max-width: 600px;

    font-size: 20px;
    line-height: 1.2;
    text-align: center;

    margin: 0 auto 45px;
  }

  &__card {
    display: none;
    position: relative;

    box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.3);
    border: 3px solid #ffe056;
    background: #F2F2F2;
    border-radius: 7px;

    padding: 12px 15px 15px;

    &.active {
      display: block;
    }

    &-title {
      font-weight: 500;
      line-height: 1.1;
      text-transform: uppercase;

      margin-bottom: 8px;
    }

    &-content {
      font-size: 15px;

      ul, p {
        line-height: 1.3;

        margin-top: 2px;
      }
    }
  }

  &__form {
    display: none;
    margin-top: 24px;

    &.active {
      display: flex;
    }

    &-group {
      padding: 30px 27px 15px;

      &.is-highlighted {
        background: #f2f2f2;
      }

      &-title {
        font-size: 17px;
        line-height: 1;
        text-align: center;

        margin-bottom: 20px;
      }

      &-body {
        .form-group {
          &__item {
            margin-bottom: 20px;

            .checkbox-style {
              &__custom {
                width: 15px;
                height: 15px;
              }

              &__label {
                font-size: 14px;
              }
            }

            &--numb {
              input {
                max-width: 70px;

                text-align: center;
                font-size: 27px;

                border-color: $primary-color;
                border-radius: 50px;
              }

              .form-group__item-info {
                display: block;

                font-size: 14px;
                text-align: center;

                margin-top: 15px;
              }
            }
          }
        }
      }
    }
  }

  &-type {
    position: relative;

    &__checker {
      display: flex;
      justify-content: center;

      margin-bottom: 15px;

      .checkbox-style {
        &__custom {
          width: 19px;
          height: 19px;
        }

        &__label {
          display: none;
        }
      }
    }

    &__photo {
      overflow: hidden;

      border: 3px solid transparent;
      border-radius: 7px;
      transition: border-color .3s;

      img {
        display: block;
        width: 100%;
      }
    }
  }

  &-types {
    &__trigger {
      display: flex;
      justify-content: space-between;

      margin-bottom: 24px;

      &-item {
        flex: 0 0 160px;
        max-width: 160px;

        cursor: pointer;

        &:hover {
          .selection-product-type {
            &__photo {
              border-color: rgba(255, 224, 86, 0.5);
            }
          }
        }

        &.active {
          .selection-product-type {
            &__photo {
              border-color: #ffe056;
            }

            &:before {
              content: "";

              position: absolute;
              left: 50%;
              top: 100%;
              width: 43px;
              height: 43px;

              background: #ffe056;
              transform: translate3d(-50%, 0, 0) rotate(-45deg);

              margin-top: 15px;
            }
          }
        }
      }
    }

    &__body {
      &-item {
        display: none;

        &.active {
          display: block;
        }
      }
    }
  }
}
